<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'regions' }" class="button">
        Manage Regions
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Region NO" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. Name"
                required
              />
            </b-field>
            <b-field label="Region Slug" horizontal>
              <b-input
                v-model="form.slug"
                placeholder="e.g. Slug"
                required
              />
            </b-field>

            <b-field label="Status" horizontal>
                <b-select placeholder="Is Active?" v-model="form.is_active">
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </b-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Region Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          @header-icon-click="trashModal(form);$router.replace({ name: 'regions' })"
        >
          <b-field label="Id">
            <b-input :value="form.id" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Slug">
            <b-input :value="form.slug" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created At">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Updated At">
            <b-input
              :value="form.updated_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'RegionForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.name : 'New Region'
      return ['Admin', 'Regions', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? this.form.name : 'Create Region'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit Region' : 'New Region'
    },
    ...mapState('region', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('region', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    getClearFormObject () {
      return {
        id: null,
        region_no: null,
        location: null,
        msp_id: null,
        is_active: null,
        created_at: null,
        updated_at: null
      }
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'regions' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          // console.log(this.item)
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'region.new' })
          }
        })
      }
    },
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) router.push({ name: 'regions' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    }
  }
}
</script>
